import { graphql, PageProps } from "gatsby"
import _ from "lodash"
import React from "react"

import { CategoryPageContext } from "../interfaces/pageContext"
import CollectionPage from "./CollectionPage"

export type CategoryPageProps = PageProps<any, CategoryPageContext>

const CategoryPage: React.FC<CategoryPageProps> = (props) => {
  const { pageContext, data } = props
  const { category } = pageContext
  const navigation = [
    {
      heading: `${category} Marken`,
      links: _.sortBy(
        data.categoryBrandPages.edges.map(({ node }: any) => ({
          to: node.path,
          text: `${node.context.brand} ${category}`,
        })),
        [(link): string => link.text.toLowerCase()]
      ),
    },
  ]
  return (
    <CollectionPage
      name={`${category}`}
      description={`${category} online kaufen bei buybags.de. Entdecke jetzt schwarze, weiße oder braune Taschen für Damen von Guess, Bree, Aigner und Michael Kors.`}
      primaryKeyword={`${category} kaufen`}
      secondaryKeyword={`Taschen Online Shop`}
      seoContent={
        data.allCategoriesJson.edges ? data.allCategoriesJson.edges[0].node.description : []
      }
      navigation={navigation}
      {...props}
    />
  )
}

export default CategoryPage

export const query = graphql`
  query CategoryPageQuery($category: String!, $skip: Int!, $limit: Int!) {
    allProductsCsv(
      filter: { fields: { category: { eq: $category } } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          fields {
            brand
            colors
            deepLink
            deliveryCost
            deliveryTime
            description
            gtin
            images {
              alt
              src
              title
            }
            material
            name
            price
            priceBefore
            sale
            slug
          }
        }
      }
      totalCount
    }
    allCategoriesJson(filter: { name: { eq: $category } }) {
      edges {
        node {
          description {
            type
            value
          }
        }
      }
    }
    categoryBrandPages: allSitePage(
      filter: {
        context: {
          category: { eq: $category }
          humanPageNumber: { eq: 1 }
          type: { eq: "CategoryBrandPage" }
        }
      }
      limit: 24
      sort: { fields: [context___numberOfPages], order: DESC }
    ) {
      edges {
        node {
          path
          context {
            brand
          }
        }
      }
    }
  }
`
